import { useQuery } from '@tanstack/react-query';
import client from './client';

const { post } = client;

export type SignInParams = {
    email: string;
    password: string;
    remember?: boolean;
};

export type ClaimOfferParams = {
    email: string;
    code: string;
};

export type UpdateOfferParams = {
    code: string;
};

export const postSignIn = (data: SignInParams) => post('/sign-in', data);
export const postSignOut = () => post('/sign-out');

export const claimOfferSignIn = (data: ClaimOfferParams) =>
    post('/create-account?acquisition=true', data);

export const updateOfferSignIn = (
    subscription: string,
    data: UpdateOfferParams
) => client.put(`/api/subscriptions/${subscription}/offer`, data);

export const getOffers = () => client.get<any>('/api/offers');

export function useOffers() {
    const query = useQuery(
        ['offers'],
        async () => {
            const { data } = await getOffers();
            return data;
        },
        {
            retry: false,
        }
    );

    const { data: offersResult, ...queryResult } = query;
    return { offersResult, ...queryResult };
}

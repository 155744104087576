import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../types';

const initialState: any = {
    data: JSON.parse(localStorage.getItem('giftCard')!) || [],
};

export const giftCardsDataSlice = createSlice({
    name: 'gift-cards-data',
    initialState,
    reducers: {
        setData(state, { payload }: PayloadAction<any>) {
            const { code } = payload;
            if (!state.data.includes(code)) {
                state.data.push(payload);
            }
        },
    },
});

export default giftCardsDataSlice.reducer;

export const { setData } = giftCardsDataSlice.actions;

const selectSelf = (state: RootState) => state.giftCardsData;

export const selectData = createSelector(selectSelf, (s) => s.data);

import config from '@/utils/config';

// eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
declare let _learnq: any;

const { keys } = JSON.parse(config);
const { klaviyo } = keys;

function islive() {
    if (klaviyo) return true;

    return true;
}

// eslint-disable-next-line func-names
const useKlaviyoIdentify = function (user: any) {
    return new Promise<void>((resolve, reject) => {
        try {
            const theUser = user;
            if (theUser) {
                const payload = {
                    $email: theUser?.email,
                    $id: theUser?.id,
                    $first_name: theUser?.firstName,
                    $last_name: theUser?.lastName,
                    $phone_number: theUser?.phone,
                    $consent: [],
                };
                if (theUser?.sms) {
                    (payload as any).$consent.push('sms');
                }
                if (theUser?.newsletter) {
                    (payload as any).$consent.push('email');
                }
                if (islive()) {
                    // We set an interval, because learnq is not defined when page is loaded
                    let intervalLoop = 0;
                    const interval = setInterval(() => {
                        intervalLoop += 1;
                        if (typeof _learnq.identify === 'function') {
                            _learnq.identify(
                                payload,
                                undefined,
                                undefined,
                                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                                (_: any) => resolve()
                            );
                            clearInterval(interval);
                        }
                        if (intervalLoop > 4) {
                            clearInterval(interval);
                        }
                    }, 1000);
                }
            }
        } catch (error) {
            reject(error);
        }
    });
};
export function useKlaviyoStartedSubscriptionProcess(user: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push(['track', 'Started Subscription Process']);
    });
}

// export function useKlaviyoSubmitGetStartedForm(user: any) {
//     console.log('useKlaviyoSubmitGetStartedForm');
//     useKlaviyoIdentify(user).then(() => {
//         console.log('Submit Get Started Form');
//         _learnq.push(['track', 'Submit Get Started']);
//     });
// }

export function useKlaviyoInitiateChooseBoxType(user: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push(['track', 'Initiate Choose Box Type']);
    });
}

export function useKlaviyoInitiateChooseBoxSize(user: any, type: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push([
            'track',
            'Initiate Choose Box Size',
            {
                BoxType: type,
            },
        ]);
    });
}
export function useKlaviyoInitiateChooseCuratedBox(user: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push(['track', 'Initiate Choose Curated Box']);
    });
}

export function useKlaviyoInitiateChooseItems(user: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push(['track', 'Initiate Choose Items']);
    });
}

export function useKlaviyoInitiateChooseAddons(user: any, size: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push([
            'track',
            'Initiate Choose Addons',
            {
                BoxSize: size,
            },
        ]);
    });
}

export function useKlaviyoInitiateUpdateItems(user: any, products: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push([
            'track',
            'Initiate Update Items',
            {
                Products: JSON.stringify(
                    products.map((product: any) => ({
                        id: product.id,
                        name: product.name,
                        quantity: product.selection.quantity,
                    }))
                ),
            },
        ]);
    });
}

export function useKlaviyoUpdateItems(user: any, products: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push([
            'track',
            'Update Items',
            {
                Products: JSON.stringify(
                    products
                        .filter((product: any) => product.quantity > 0)
                        .map((product: any) => ({
                            id: product.product_id,
                            quantity: product.quantity,
                        }))
                ),
            },
        ]);
    });
}

// export function useKlaviyoSubmitChooseBoxTypeForm(user: any) {
//     console.log('useKlaviyoSubmitChooseBoxTypeForm');
//     useKlaviyoIdentify(user).then(() => {
//         console.log('Submit Choose Box Type');
//         _learnq.push(['track', 'Submit Choose Box Type']);
//     });
// }

export function useKlaviyoInitiateCheckout(user: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push(['track', 'Initiate Checkout']);
    });
}

export function useKlaviyoInitiateFrequencyChange(
    user: any,
    oldFrequency: any,
    newFrequency: any
) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push([
            'track',
            'Update frequency',
            {
                OldFrequency: `${oldFrequency} days`,
                NewFrequency: `${newFrequency} days`,
            },
        ]);
    });
}

export function useKlaviyoError(user: any, error: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push([
            'track',
            'Uncaught Error',
            {
                Status: 500,
                Message: JSON.stringify(error),
            },
        ]);
    });
}

export function useKlaviyoSubmitCheckoutRequest(user: any, values: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push([
            'track',
            'Submit Checkout Request',
            {
                Country: values?.country ?? '',
                Address: values?.shippingAddressLine_1 ?? '',
                City: values?.shippingCity ?? '',
                State: values?.shippingState ?? '',
                Suite: values?.suite ?? '',
            },
        ]);
    });
}

export function useKlaviyoCheckoutError(user: any, error: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push([
            'track',
            'Checkout Error',
            {
                Status: error?.response?.status,
                Message: error?.response?.data?.message
                    ? JSON.stringify(error.response.data.message)
                    : JSON.stringify(error),
            },
        ]);
    });
}

export function useKlaviyoRequestError(user: any, error: any) {
    useKlaviyoIdentify(user).then(() => {
        _learnq.push([
            'track',
            'API Request Error',
            {
                Status: error?.response?.status,
                Message: error?.message,
                Errors: JSON.stringify(error?.response?.data?.errors),
            },
        ]);
    });
}

import { useMutation } from '@tanstack/react-query';
import queryClient from '@/utils/queryClient';
import client from './client';

const { get } = client;

export type DiscountMethodsModel = any;

export const getDiscountMethods = (
    discountCode: string,
    subscription: string
) =>
    get<DiscountMethodsModel>(
        subscription
            ? `/api/discounts/${discountCode}?subscription=${subscription}`
            : `/api/discounts/${discountCode}`
    );

export const addDiscountMethod = (subscription: string, promoCode: string) =>
    client.put<any>(
        `/api/subscriptions/${subscription}/discount?code=${promoCode}`
    );

export function useAddDiscountMethod(subscription: string, promoCode: string) {
    const query = useMutation(
        async () => {
            const { data } = await addDiscountMethod(subscription, promoCode);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['singleMenu']);
            },
        }
    );
    const { data: addDiscountResult, ...queryResult } = query;
    return { addDiscountResult, ...queryResult };
}

export const deleteDiscountMethod = (subscription: string) =>
    client.delete<any>(`/api/subscriptions/${subscription}/discount`);

export function useDeleteDiscountMethod(subscription: string) {
    const query = useMutation(
        async () => {
            const { data } = await deleteDiscountMethod(subscription);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['discount']);
            },
        }
    );
    const { data: deleteDiscountResult, ...queryResult } = query;
    return { deleteDiscountResult, ...queryResult };
}

export const addSubscriptionPromotionChoices = (
    subscription: string,
    promoCode: string,
    payload: any | undefined
) =>
    client.put<any>(
        `/api/subscriptions/${subscription}/discount?code=${promoCode}`,
        payload
    );

export function useAddSubscriptionPromotionChoices(
    subscription: string,
    promoCode: string,
    payload: any | undefined
) {
    const query = useMutation(
        async () => {
            const { data } = await addSubscriptionPromotionChoices(
                subscription,
                promoCode,
                payload
            );
            return data;
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['singleMenu']);
            },
        }
    );
    const { data: addDiscountResult, ...queryResult } = query;
    return { addDiscountResult, ...queryResult };
}

export const updateSubscriptionPromotionChoices = (
    subscription: string,
    data: any
) =>
    client.put<any>(
        window.Echo.socketId()
            ? `/api/subscriptions/${subscription}/discount/choices`
            : `/api/socketless/subscriptions/${subscription}/discount/choices`,
        data
    );

export function useUpdateSubscriptionPromotionChoices(
    subscription: string,
    payload: any
) {
    return useMutation(async () => {
        const { data } = await updateSubscriptionPromotionChoices(
            subscription,
            payload
        );
        return data;
    });
}

import { ThreeDots } from 'react-loader-spinner';

type ThreeDotsProps = {
    visible: boolean;
    color?: string;
};

export default function ThreeDotsLoader({ visible, color }: ThreeDotsProps) {
    return (
        <ThreeDots
            radius="9"
            color={color || '#fd4825'}
            ariaLabel="three-dots-loading"
            wrapperClass="fixed m-auto left-0 right-0 top-0 bottom-0 loading-svg z-[1000] backdrop-blur w-full"
            visible={visible}
        />
    );
}

import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { XIcon } from '../common/Icons';

interface Props {
    title: string;
    open?: boolean;
    onClose(open: boolean): void;
    children?: React.ReactNode;
}

export default function Modal({ title, open, onClose, children }: Props) {
    return (
        <AlertDialog.Root open={open} onOpenChange={onClose}>
            <AlertDialog.Portal>
                <AlertDialog.Overlay className="fixed inset-0 z-[100] bg-black bg-opacity-25" />
                <AlertDialog.Content className="fixed top-1/2 left-1/2 z-[101] m-auto m-auto flex max-h-[100vh] w-11/12 max-w-md -translate-y-2/4 -translate-x-2/4 transform  flex-col border-2 border-black bg-white text-left align-middle shadow-block transition-all">
                    <AlertDialog.Title className="flex items-center justify-between bg-black py-2 px-4 text-lg font-medium leading-6 text-white">
                        {title}
                    </AlertDialog.Title>
                    <AlertDialog.Cancel
                        id={title}
                        className="absolute top-2 right-2"
                    >
                        <XIcon
                            className="h-6 w-6 text-white"
                            onClick={() => onClose(false)}
                        />
                    </AlertDialog.Cancel>
                    <div className="mt-2 min-h-0 flex-1 overflow-y-auto px-6 py-4">
                        {children}
                    </div>
                </AlertDialog.Content>
            </AlertDialog.Portal>
        </AlertDialog.Root>
    );
}

import { useCallback, useRef } from 'react';

export default function useRefStableCallback<T extends (...args: any) => any>(
    callback: T
): T {
    const callbackRef = useRef(callback);
    callbackRef.current = callback;
    /* eslint-disable */
    return useCallback(
        ((...args: any): any => {
            return callbackRef.current.apply(null, args);
        }) as any,
        []
    );
}

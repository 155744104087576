/**
 * Utility for strongly typed path notation strings.
 *
 * Based on the work of @diegohaz https://twitter.com/diegohaz/status/1309489079378219009?lang=en
 *
 * @author Dominic Lacaille <dominic@swoo.ca>
 */

type PathImpl<T, K extends keyof T> = K extends string
    ? T[K] extends Record<string, any>
        ? T[K] extends ArrayLike<any>
            ? K | `${K}.${PathImpl<T[K], Exclude<keyof T[K], keyof any[]>>}`
            : K | `${K}.${PathImpl<T[K], keyof T[K]>}`
        : K
    : never;

export type Path<T> = PathImpl<T, keyof T> | keyof T;

export type PathValue<
    T,
    P extends Path<T>
> = P extends `${infer K}.${infer Rest}`
    ? K extends keyof T
        ? Rest extends Path<T[K]>
            ? PathValue<T[K], Rest>
            : never
        : never
    : P extends keyof T
    ? T[P]
    : never;

/**
 * May be used to strongly type a lodash-style dot notation path string.
 *
 * Ex:
 * ```ts
 * type MyModel = {
 *   firstName: string,
 *   lastName: string,
 *   projects: { name: string }[],
 * } as const;
 *
 * const stronglyTypedPath = path<typeof object>("projects.0.name");
 * ```
 *
 * Note that objects with complex types or 'any' fields might cause typescript
 * to throw: `Type instantiation is excessively deep and possibly infinite.ts(2589)`
 * In this case, simplify or define the type strictly to fix this error.
 */
const dot = <T>(p: Path<T>) => p;

/**
 * See @type {dot}
 *
 * This lets you create a pre-typed dot function as a shortcut.
 */
export const makeDot =
    <T>() =>
    (p: Path<T>) =>
        p;

export default dot;

import clsx from 'clsx';
import { useField } from 'formik';
import { ReactNode, createContext, useMemo, useState } from 'react';
import makeid from '@/utils/makeid';

interface InputGroupContextProps {
    name: string;
}

export type InputGroupProps = InputGroupContextProps & {
    title?: ReactNode;
    subtitle?: ReactNode;
    label?: ReactNode;
    hint?: ReactNode;
    help?: ReactNode;
    className?: string;
    openEndField?: string;
    nakedStyle?: Boolean;
};

export interface InputGroupContextState extends Omit<InputGroupProps, 'input'> {
    inputId?: string;
    errorId?: string;
}

export const InputGroupContext = createContext<InputGroupContextState>({
    name: 'undefined',
});

export default function InputGroup(
    props: React.PropsWithChildren<InputGroupProps>
) {
    const [randomId] = useState(makeid(16));

    const {
        children,
        name = randomId,
        label,
        title,
        subtitle,
        help,
        nakedStyle,
        hint,
        className,
    } = props;

    const [, meta] = useField(name);
    let { error } = meta;
    const { touched } = meta;
    if (!touched) error = undefined;

    const inputId = `${name}_input`;
    const errorId = `${name}_error`;

    const contextValue = useMemo(
        () => ({
            inputId,
            errorId,
            name,
            label,
            hint,
        }),
        [inputId, errorId, name, label, hint]
    );

    return (
        <div className={clsx(!nakedStyle && 'space-y-2', className)}>
            {(title || subtitle) && (
                <div>
                    {title && (
                        <label
                            className="font-agrandir text-xs font-bold uppercase tracking-wider text-system"
                            htmlFor={inputId}
                        >
                            {title}
                        </label>
                    )}
                    {subtitle && (
                        <div className="text-sm leading-5 text-system">
                            {subtitle}
                        </div>
                    )}
                </div>
            )}
            {hint && (
                <div className="flex justify-between">
                    <label htmlFor={inputId} className="block text-sm">
                        {label}
                    </label>
                    <span className="text-sm text-hint" id="email-optional">
                        {hint}
                    </span>
                </div>
            )}
            {label && !hint && (
                <label htmlFor={inputId} className="-mb-2 block text-sm">
                    {label}
                </label>
            )}
            {children && (
                <InputGroupContext.Provider value={contextValue}>
                    <div className={clsx(!nakedStyle && 'my-1')}>
                        {children}
                    </div>
                </InputGroupContext.Provider>
            )}
            {(error || help) && (
                <div
                    id={error && errorId}
                    className={clsx(
                        'text-xs',
                        error ? 'text-error' : 'text-hint'
                    )}
                >
                    {error || help}
                </div>
            )}
        </div>
    );
}

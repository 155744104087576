import { useFormikContext } from 'formik';
import { HTMLProps, PropsWithChildren } from 'react';
import handleFormErrors from '@/utils/handleFormErrors';

type Props = {} & HTMLProps<HTMLFormElement>;

export default function Form({
    children,
    ...otherProps
}: PropsWithChildren<Props>) {
    const context = useFormikContext<any>();
    const { validateForm, handleSubmit } = context;
    return (
        <form
            {...otherProps}
            onSubmit={async (e) => {
                const errors = await validateForm();
                if (Object.keys(errors).length) {
                    handleFormErrors(context, errors);
                    e.preventDefault();
                } else {
                    handleSubmit(e);
                }
            }}
        >
            {children}
        </form>
    );
}

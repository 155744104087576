import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { MinusIcon, PlusIcon } from '@/components/common/Icons';
import { decrementItem } from '@/store/yourItems/yourItemsSlice';

type Props = {
    id?: string;
    count: number;
    // Smallest number between available products in stock  and how many items can be added to the box still.
    // Will be used to disable the + button and prevent setting a value higher than available.
    available?: number;
    onCountChange(value: number): void;
    readonly?: boolean;
    simple?: boolean;
    maxSelection?: number | boolean;
    removeCart?: any;
    product?: any;
};

export default function ProductCountInput({
    id,
    count,
    available,
    onCountChange,
    readonly,
    simple,
    maxSelection,
    removeCart,
    product,
}: Props) {
    const dispatch = useDispatch();
    return (
        <div className="flex items-center">
            <button
                type="button"
                onClick={() => {
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    count === 1
                        ? dispatch(decrementItem({ id }))
                        : onCountChange(count - 1);
                    if (removeCart && product) {
                        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                        removeCart(product, 1);
                    }
                }}
                disabled={readonly || count <= 0}
                className="p-2 transition-colors hover:text-primary disabled:text-system disabled:opacity-25"
            >
                <MinusIcon
                    className={clsx(
                        !simple &&
                            'h-[6px] w-[21px] xs:h-[4px] xs:w-[14px] sm:h-[6px] sm:w-[21px]'
                    )}
                />
            </button>
            <input
                type="text"
                className={clsx(
                    'mx-2 h-[38px] w-[38px] border border-secondary px-0 text-center text-lg font-medium xs:h-[30px] xs:w-[30px] xs:text-base sm:h-[38px] sm:w-[38px] sm:text-lg',
                    simple && 'mx-1 h-[28px] border-none'
                )}
                value={count}
                disabled={readonly}
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                    // Value has to be a natural number.
                    const intVal = parseInt(e.target.value, 10);
                    // Discard NaN, Infinite, and other invalid numbers.
                    if (!Number.isInteger(intVal)) return;
                    // New value can't be negative or greater than what was already selected + what is available.
                    const clamped = Math.max(
                        // If available is undefined, do not limit selection.
                        available === undefined
                            ? intVal
                            : Math.min(intVal, count + available),
                        0
                    );
                    onCountChange(clamped);
                }}
            />

            <button
                type="button"
                onClick={() => onCountChange(count + 1)}
                disabled={
                    readonly ||
                    (available !== undefined && available <= 0) ||
                    count === maxSelection
                }
                className="p-2 transition-colors hover:text-primary disabled:text-system disabled:opacity-25"
            >
                <PlusIcon
                    className={clsx(
                        !simple &&
                            'h-[21px] w-[21px] xs:h-[14px] xs:w-[14px] sm:h-[21px] sm:w-[21px]'
                    )}
                />
            </button>
        </div>
    );
}

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../types';

const initialState: any = {
    error: null,
    giftCardsError: false,
    giftCardsCheckout: false,
    unauthenticated: false,
    unexpectedError: false,
};

export const giftCardsErrorSlice = createSlice({
    name: 'gift-cards-error',
    initialState,
    reducers: {
        resetGiftCardsError: () => initialState,
        setError(state, { payload }: PayloadAction<any>) {
            state.error = payload;
        },
        setGiftCardsError(state, { payload }: PayloadAction<any>) {
            state.giftCardsError = payload;
        },
        setAttemptedGiftCardsCheckout(state, { payload }: PayloadAction<any>) {
            state.giftCardsCheckout = payload;
        },
        setUnauthenticated(state, { payload }: PayloadAction<any>) {
            state.unauthenticated = payload;
        },
        setUnexpectedError(state, { payload }: PayloadAction<any>) {
            state.unexpectedError = payload;
        },
    },
});

export default giftCardsErrorSlice.reducer;

export const {
    setError,
    setGiftCardsError,
    setAttemptedGiftCardsCheckout,
    setUnauthenticated,
    setUnexpectedError,
    resetGiftCardsError,
} = giftCardsErrorSlice.actions;

const selectSelf = (state: RootState) => state.giftCardsError;

export const selectError = createSelector(selectSelf, (s) => s.error);
export const selectGiftCardsError = createSelector(
    selectSelf,
    (s) => s.giftCardsError
);
export const selectCheckout = createSelector(
    selectSelf,
    (s) => s.giftCardsCheckout
);
export const selectUnauthenticated = createSelector(
    selectSelf,
    (s) => s.unauthenticated
);
export const selectUnexpectedError = createSelector(
    selectSelf,
    (s) => s.unexpectedError
);

import clsx from 'clsx';
import { FieldValidator, useField } from 'formik';
import { ChangeEventHandler, useContext } from 'react';
import useOptionsField from '@/hooks/useOptionsField';
import Option from '@/types/Option';
import { InputGroupContext } from './InputGroup';

type CheckBoxProps = {
    id?: string;
    name?: string;
    value?: string;
    label: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    description?: string;
    error?: string;
    invalid?: boolean;
    inline?: boolean;
    checked?: boolean;
    checkboxRight?: boolean;
};

export function CheckBox({
    id,
    name,
    label,
    description,
    onChange,
    invalid,
    error,
    value,
    checked,
    inline,
    checkboxRight,
}: CheckBoxProps) {
    return (
        <div
            className={clsx(
                'relative flex items-start space-x-3',
                checkboxRight && 'flex-row-reverse'
            )}
        >
            <div className="flex h-5 items-center">
                <input
                    id={id}
                    name={name}
                    aria-invalid={invalid}
                    aria-describedby={`${id}-description`}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    type="checkbox"
                    className={clsx('form-checkbox', invalid && 'error')}
                />
            </div>
            <div className={clsx('text-sm', checkboxRight && 'flex-1')}>
                <label htmlFor={id} className="font-medium text-system">
                    {label}
                </label>
                {inline && ' '}
                {(error || description) && (
                    <span
                        id={`${id}-description`}
                        className={clsx(
                            error ? 'text-error' : 'text-hint',
                            !inline && 'block'
                        )}
                    >
                        {inline && <span className="sr-only">{label} </span>}
                        {error || description}
                    </span>
                )}
            </div>
        </div>
    );
}

type SingleCheckBoxProps = {
    id?: string;
    name?: string;
    label: string;
    description?: string;
    validate?: FieldValidator;
    inline?: boolean;
    checkboxRight?: boolean;
};

export function SingleCheckBox(props: SingleCheckBoxProps) {
    const { inputId: ctxId, name: ctxName } = useContext(InputGroupContext);
    const {
        id = ctxId,
        name = ctxName,
        label,
        description,
        validate,
        inline,
        checkboxRight,
    } = props;

    const [, meta, helpers] = useField({
        name,
        validate,
    });

    const { setValue } = helpers;
    let { error } = meta;
    const { value, touched } = meta;
    if (!touched) error = undefined;

    return (
        <CheckBox
            id={id}
            name={name}
            invalid={Boolean(error)}
            description={description}
            checked={Boolean(value)}
            onChange={(e) => {
                setValue(e.target.checked);
            }}
            label={label}
            inline={inline}
            checkboxRight={checkboxRight}
        />
    );
}

type CheckBoxGroupProps<T> = {
    options: Option<T>[];
    id?: string;
    name?: string;
    inline?: boolean;
    checkboxRight?: boolean;
    divide?: boolean;
    validate?: FieldValidator;
};

export default function CheckBoxGroup<T = string>(
    props: CheckBoxGroupProps<T>
) {
    const { inputId: ctxId, name: ctxName } = useContext(InputGroupContext);
    const {
        id = ctxId,
        name = ctxName,
        options,
        validate,
        inline,
        divide,
        checkboxRight,
    } = props;

    const [{ selected = [], setSelected }, { error, touched }] =
        useOptionsField({
            name,
            validate,
            options,
            multiple: true,
        });

    const onCheckBoxChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        const targetValue = e.target.value;
        const option = options.find((o) => String(o.id) === targetValue);
        if (!option) return;
        if (e.target.checked && !selected.includes(option)) {
            setSelected([...selected, option]);
        } else if (!e.target.checked && selected.includes(option)) {
            const newValues = selected.filter((opt) => opt !== option);
            setSelected(newValues);
        }
    };

    return (
        <div
            className={clsx('child:py-4', divide && 'divide-y divide-gray-200')}
        >
            {options.map((option) => (
                <CheckBox
                    id={`${id}-${option.id}`}
                    name={`${name}-${option.id}`}
                    label={option.label}
                    description={option.description}
                    key={option.id}
                    checked={selected.includes(option)}
                    checkboxRight={checkboxRight}
                    inline={inline}
                    onChange={onCheckBoxChange}
                    invalid={Boolean(error) && touched}
                    value={String(option.id)}
                />
            ))}
        </div>
    );
}

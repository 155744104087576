import * as AlertDialog from '@radix-ui/react-alert-dialog';
import IconType from '@/types/IconType';

interface Props {
    open?: boolean;
    onClose?(open: boolean): void;
    title: string;
    description?: string;
    icon?: IconType;
    children: any;
}

export default function AlertModal({
    open,
    onClose,
    title,
    description,
    icon: Icon,
    children,
}: Props) {
    return (
        <AlertDialog.Root open={open} onOpenChange={onClose}>
            <AlertDialog.Portal>
                <AlertDialog.Overlay className="fixed inset-0 z-50 bg-black bg-opacity-25" />
                <AlertDialog.Content className="fixed top-1/2 left-1/2 z-50 m-auto flex max-h-[100vh] w-11/12 max-w-md -translate-y-2/4 -translate-x-2/4 transform flex-col rounded bg-white text-left align-middle transition-all">
                    <div className="p-5 md:p-9">
                        <div>
                            {Icon && (
                                <Icon
                                    className="h-12 w-12 stroke-1"
                                    aria-hidden="true"
                                />
                            )}
                        </div>
                        <div className="mt-2 text-xl font-medium lg:text-2xl">
                            {title}
                        </div>
                        {description && (
                            <div className="mt-2 text-sm font-normal text-[#898899]">
                                {description}
                            </div>
                        )}
                        {children}
                    </div>
                </AlertDialog.Content>
            </AlertDialog.Portal>
        </AlertDialog.Root>
    );
}

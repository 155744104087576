import React, {
    createContext,
    Dispatch,
    useReducer,
    useContext,
    Reducer,
    useId,
    useMemo,
    useLayoutEffect,
} from 'react';
import produce from 'immer';
import { Slot } from '@radix-ui/react-slot';

type Event<K extends string, T = undefined> = T extends undefined
    ? { type: K }
    : { type: K; payload: T };

type NavigationAction =
    | Event<'registerItem', string>
    | Event<'unregisterItem', string>
    | Event<'registerNav', string>
    | Event<'unregisterNav', string>
    | Event<'focusOn', string>
    | Event<'focusOff', string>
    | Event<'focusPrev', string>
    | Event<'focusNext', string>;

type NavigationState = {
    items: string[];
    nav?: string;
    focused?: string;
};

type NavigationDispatcher = Dispatch<NavigationAction>;

type NavigationContext = [NavigationState, NavigationDispatcher];

const initialState: NavigationState = {
    items: [],
};

const context = createContext<NavigationContext>([initialState, () => {}]);

function sortByDOMPosition(a: string, b: string) {
    const aEl = document.getElementById(a) as HTMLElement;
    const bEl = document.getElementById(b) as HTMLElement;
    if (!aEl && !bEl) return 0;
    if (!aEl) return -1;
    if (!bEl) return 1;
    return aEl.compareDocumentPosition(bEl) === Node.DOCUMENT_POSITION_PRECEDING
        ? 1
        : -1;
}

const reducer: Reducer<NavigationState, NavigationAction> = (state, action) =>
    produce(state, (draft) => {
        switch (action.type) {
            case 'registerItem':
                draft.items = [...draft.items, action.payload].sort(
                    sortByDOMPosition
                );
                break;
            case 'unregisterItem':
                draft.items.splice(draft.items.indexOf(action.payload), 1);
                break;
            case 'registerNav':
                draft.nav = action.payload;
                break;
            case 'unregisterNav': {
                if (draft.nav === action.payload) draft.nav = undefined;
                break;
            }
            case 'focusOn':
                draft.focused = action.payload;
                break;
            case 'focusOff':
                draft.focused = undefined;
                break;
            case 'focusNext': {
                if (!draft.focused) {
                    if (draft.items.length)
                        document.getElementById(draft.items[0])?.focus();
                    break;
                }
                const focusedIndex = draft.items.indexOf(action.payload);
                if (focusedIndex < draft.items.length) {
                    const next = draft.items[focusedIndex + 1];
                    const nextEl = document.getElementById(next);
                    if (nextEl) {
                        nextEl.focus();
                        draft.focused = next;
                    }
                }
                break;
            }
            case 'focusPrev': {
                if (!draft.focused) {
                    if (draft.items.length)
                        document.getElementById(draft.items[0])?.focus();
                    break;
                }
                const focusedIndex = draft.items.indexOf(action.payload);
                if (focusedIndex > 0) {
                    const prev = draft.items[focusedIndex - 1];
                    const prevEl = document.getElementById(prev);
                    if (prevEl) {
                        prevEl.focus();
                        draft.focused = prev;
                    }
                }
                break;
            }
            default:
        }
    });

type NavigationProps = {
    children: React.ReactNode;
    className?: string;
};

function Navigation({ children, className }: NavigationProps) {
    const key = `navigation-${useId()}`;
    const value = useReducer(reducer, initialState);
    const [, dispatch] = value;

    useLayoutEffect(() => {
        dispatch({ type: 'registerNav', payload: key });
        return () => {
            dispatch({ type: 'unregisterNav', payload: key });
        };
    }, [key, dispatch]);

    return (
        <nav id={key} className={className}>
            <context.Provider value={value}>{children}</context.Provider>
        </nav>
    );
}

function useNavigationItem(key: string) {
    const navigationContext = useContext(context);
    const [, dispatch] = navigationContext;

    useLayoutEffect(() => {
        dispatch({ type: 'registerItem', payload: key });
        return () => {
            dispatch({ type: 'unregisterItem', payload: key });
        };
    }, [key, dispatch]);

    return navigationContext;
}

const LinkDefaultElement = 'a';

type LinkRenderArgs = {
    focused: boolean;
};

type LinkProps = {
    asChild?: boolean;
    children:
        | JSX.Element
        | JSX.Element[]
        | string
        | ((args: LinkRenderArgs) => React.ReactNode);
};

Navigation.Link = React.forwardRef<
    React.ElementRef<typeof LinkDefaultElement>,
    Omit<React.ComponentProps<typeof LinkDefaultElement>, 'children'> &
        LinkProps
>(({ children, asChild, ...props }, forwardedRef) => {
    const Comp = asChild ? Slot : LinkDefaultElement;
    const key = `navigation-link-item-${useId()}`;
    const [state, dispatch] = useNavigationItem(key);
    const focused = state.focused === key;
    const args = useMemo(() => ({ focused }), [focused]);

    return (
        <Comp
            {...props}
            id={key}
            ref={forwardedRef}
            onFocus={() => {
                dispatch({ type: 'focusOn', payload: key });
            }}
            onBlur={() => {
                dispatch({ type: 'focusOff', payload: key });
            }}
            onKeyDown={(e) => {
                switch (e.key) {
                    case 'Right':
                    case 'ArrowRight':
                    case 'Down':
                    case 'ArrowDown':
                        e.preventDefault();
                        dispatch({ type: 'focusNext', payload: key });
                        break;
                    case 'Left':
                    case 'ArrowLeft':
                    case 'Up':
                    case 'ArrowUp':
                        e.preventDefault();
                        dispatch({ type: 'focusPrev', payload: key });
                        break;
                    case 'Enter':
                    case ' ':
                        e.preventDefault();
                        if ('click' in e.target) {
                            (e.target as HTMLElement).click();
                        }
                        break;
                    default:
                }
            }}
        >
            {typeof children === 'function' ? children(args) : children}
        </Comp>
    );
});

export default Navigation;

import { PropsWithChildren } from 'react';
import formatDollarAmount from '@/utils/formatDollarAmount';

interface BillingProductListingCardProps {
    name: string;
    productAmount: number;
    productPrice?: number;
}

export default function BillingProductListingCard({
    name,
    productAmount,
    productPrice,
}: PropsWithChildren<BillingProductListingCardProps>) {
    return (
        <div>
            <div className="flex justify-between">
                <div className="flex items-start">
                    <span className="flex h-[29px] w-[29px] items-center justify-center border bg-gray-200 font-medium">
                        <span>{productAmount}</span>
                    </span>
                    <span className="ml-[9px] w-[calc(100%_-_37px)] self-center font-neue text-xs font-medium uppercase">
                        {name}
                    </span>
                </div>
                {productPrice && (
                    <div>
                        <span className="ml-[4px] font-[700]">
                            {formatDollarAmount(productAmount * productPrice)}
                        </span>
                    </div>
                )}
            </div>
            <div role="separator" className="my-4 h-[1px] bg-secondary" />
        </div>
    );
}

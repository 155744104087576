import { FormikErrors, FormikHelpers } from 'formik';

export default function handleFormErrors(
    { setFieldTouched, setStatus }: FormikHelpers<any>,
    errors: FormikErrors<any>
) {
    const errorFields = Object.keys(errors);

    const found = errorFields.some((field) => {
        // Split the field by . and return the first part.
        [field] = field.split('.');
        try {
            // Touch the first field with an error.
            setFieldTouched(field, true, false);
            // Find the label first, else the input.
            const input = document.querySelector(
                `label[for=${field}_input], #${field}_input, [name=${field}]`
            );
            // Scroll to the input.
            input?.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'nearest',
            });
            // Keep going until we find a visible input.
            return Boolean(input);
        } catch {
            // If an exception occurred, maybe the key was not valid html id.
            return false;
        }
    });

    // If no fields are found with an error message, show the error.
    if (!found) {
        const firstError = errors[errorFields[0]] as string | string[];
        const error = Array.isArray(firstError) ? firstError[0] : firstError;
        if (error && setStatus) setStatus({ error });
    }
}

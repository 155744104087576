import type { TestConfig } from 'yup';

export default function phoneNumber(
    message: string
): TestConfig<string | undefined> {
    return {
        name: 'phoneNumber',
        test: (value) => {
            if (value) {
                if (value.startsWith('0') || value.startsWith('61')) {
                    return Boolean(
                        value &&
                            value.length >= 3 &&
                            /^\+[0-9]\d{2,14}$/.test(`+${value}`)
                    );
                }
            }
            return Boolean(
                value &&
                    value.length >= 3 &&
                    /^\+[0-9]\d{2,12}$/.test(`+${value}`)
            );
        },
        message,
    };
}

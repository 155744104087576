import { configureStore } from '@reduxjs/toolkit';
import getStartedErrorReducer from './getStarted/getStartedErrorSlice';
import getStartedLoadingReducer from './getStarted/getStartedLoadingSlice';
import getStartedReducer from './getStarted/getStartedSlice';
import giftCardsDataReducer from './giftCards/giftCardsDataSlice';
import giftCardsErrorReducer from './giftCards/giftCardsErrorSlice';
import giftCardsSuccessReducer from './giftCards/giftCardsSuccessSlice';
import userReducer from './user/userSlice';
import yourItemsReducer from './yourItems/yourItemsSlice';

const store = configureStore({
    reducer: {
        user: userReducer,
        getStarted: getStartedReducer,
        getStartedError: getStartedErrorReducer,
        getStartedLoading: getStartedLoadingReducer,
        yourItems: yourItemsReducer,
        giftCardsError: giftCardsErrorReducer,
        giftCardsSuccess: giftCardsSuccessReducer,
        giftCardsData: giftCardsDataReducer,
    },
});

export default store;

import { QueryClient } from '@tanstack/react-query';
import handleErrors from './handleErrors';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 60 * 1000, // Only refetch stale data after 1 minute to prevent API throttling.
            retry: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            onError: handleErrors,
        },
    },
});

export default queryClient;

import { Slot, Slottable } from '@radix-ui/react-slot';
import clsx from 'clsx';
import React from 'react';
import IconType from '@/types/IconType';
import SizeType from '@/types/SizeType';

const DefaultElement = 'button';

type Props = {
    asChild?: boolean;
    size?: SizeType;
    type?: 'submit' | 'reset' | 'button';
    full?: boolean;
    wide?: boolean;
    icon?: IconType;
    secondary?: boolean;
    white?: boolean;
    className?: string;
};

const Button = React.forwardRef<
    React.ElementRef<typeof DefaultElement>,
    React.ComponentProps<typeof DefaultElement> & Props
>(
    (
        {
            asChild,
            children,
            className,
            icon: Icon,
            size = 'md',
            type = 'button',
            wide,
            full,
            secondary,
            white,
            ...props
        },
        forwardedRef
    ) => {
        const Comp = asChild ? Slot : DefaultElement;
        const getStyle = () => {
            if (secondary)
                return 'bg-secondary text-white hover:bg-primary focus:outline-none focus-visible:ring focus-visible:ring-primary';
            if (white)
                return 'bg-white text-black hover:bg-black hover:text-white focus:outline-none focus-visible:ring focus-visible:ring-primary';
            return 'bg-primary text-white hover:bg-secondary focus:outline-none focus-visible:ring focus-visible:ring-black';
        };
        const getSizeClasses = () => {
            switch (size) {
                case 'xs':
                    return clsx(
                        wide ? 'px-8' : 'px-2',
                        'py-2 text-xs leading-4'
                    );
                case 'sm':
                    return clsx(
                        wide ? 'px-12' : 'px-4',
                        'py-3 text-sm leading-4'
                    );
                case 'md':
                    return clsx(wide ? 'px-16' : 'px-6', 'py-3 text-sm');
                case 'lg':
                    return clsx(wide ? 'px-24' : 'px-6', 'py-3 text-base');
                case 'xl':
                    return clsx(wide ? 'px-32' : 'px-8', 'py-4 text-base');
                case '2xl':
                    return clsx(wide ? 'px-36' : 'px-10', 'py-5 text-base');
                case '3xl':
                    return clsx(wide ? 'px-36' : 'px-12', 'py-6 text-lg');
                default:
                    return clsx(wide ? 'px-8' : 'px-3', 'py-2 text-xs');
            }
        };
        const getIconSizeClasses = () => {
            switch (size) {
                case 'lg':
                case 'xl':
                case '2xl':
                    return 'h-6 w-6';
                case '3xl':
                    return 'h-7 w-7';
                default:
                    return 'h-5 w-5';
            }
        };
        return (
            <Comp
                {...props}
                ref={forwardedRef}
                /* eslint-disable-next-line react/button-has-type */
                type={type}
                className={clsx(
                    'inline-flex items-center font-agrandir font-bold uppercase tracking-wider transition-colors disabled:bg-neutral-500 sm:tracking-widest',
                    getStyle(),
                    getSizeClasses(),
                    full && 'w-full justify-center',
                    className
                )}
            >
                {Icon && <Icon className={getIconSizeClasses()} />}
                <Slottable>{children}</Slottable>
            </Comp>
        );
    }
);

export default Button;

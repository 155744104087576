import { Combobox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { FieldValidator } from 'formik';
import {
    Fragment,
    PropsWithChildren,
    useCallback,
    useContext,
    useState,
} from 'react';
import { ChevronDownIcon } from '@/components/common/Icons';
import useOptionsField from '@/hooks/useOptionsField';
import useUnmountedRef from '@/hooks/useUnmountedRef';
import Option from '@/types/Option';
import { InputGroupContext } from './InputGroup';

interface OptionArg {
    active: boolean;
    selected: boolean;
}

type SearchSelectProps<T> = {
    id?: string;
    name?: string;
    label?: string;
    static?: boolean;
    placeholder?: string;
    rounded?: boolean;
    value?: any;
    optionLeading?(option: Option<T>, arg: OptionArg): JSX.Element;
    options: Option<T>[];
    validate?: FieldValidator;
    onChange(value: any): void;
    disabled?: boolean;
};

export default function SearchSelect<T = string>(
    props: PropsWithChildren<SearchSelectProps<T>>
) {
    const [query, setQuery] = useState('');
    const {
        inputId: ctxId,
        name: ctxName,
        errorId,
    } = useContext(InputGroupContext);
    const {
        id = ctxId,
        name = ctxName,
        label,
        placeholder = '\xa0',
        rounded,
        value,
        options,
        static: staticOpen,
        validate,
        onChange,
        disabled,
        optionLeading = (_option, { selected }: OptionArg) =>
            selected && (
                <ChevronDownIcon
                    className="h-4 w-4 md:h-5 md:w-5"
                    aria-hidden="true"
                />
            ),
    } = props;

    const [, meta, helpers] = useOptionsField({
        name,
        validate,
        options,
    });
    const { error, touched } = meta;
    const { setTouched } = helpers;

    const unmountedRef = useUnmountedRef();
    const onListBoxBlur = useCallback(() => {
        // Wait a bit before setting touched to prevent an error showing before we click on an option.
        setTimeout(() => {
            // Do nothing if we are not mounted, otherwise we'll get a console error.
            if (unmountedRef.current) return;

            setTouched(true);
        }, 500);
    }, [setTouched, unmountedRef]);

    const filteredOptions =
        query === ''
            ? options
            : options.filter((option) =>
                  option.label
                      .toLowerCase()
                      .replace(/\s+/g, '')
                      .includes(query.toLowerCase().replace(/\s+/g, ''))
              );

    const targetLabel: any = options?.find((obj) => obj.value === value);

    return (
        <div className={label && '-mt-[8px]'}>
            <Combobox value={value} onChange={onChange} disabled={disabled}>
                {label && (
                    <label htmlFor={name} className="block text-sm">
                        {label}
                    </label>
                )}
                <div className="relative mt-1">
                    <Combobox.Input
                        id={id}
                        name={name}
                        className={clsx(
                            'relative w-full cursor-default  border border-secondary bg-white py-2 pl-6 pr-10 text-left text-xs shadow-sm sm:text-sm',
                            error && touched
                                ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:outline-none focus:ring-red-500'
                                : 'border focus:border-secondary focus:outline-none focus:ring-1 focus:ring-secondary',
                            rounded && 'rounded'
                        )}
                        displayValue={(option: any) => option?.label}
                        onChange={(event) => setQuery(event.target.value)}
                    />
                    <Combobox.Button
                        className="absolute inset-y-0 flex w-full items-center pl-6 pr-6"
                        aria-invalid={Boolean(error && touched)}
                        aria-describedby={
                            error && touched ? errorId : undefined
                        }
                    >
                        {!query && (
                            <span className="block truncate">
                                <span className="text text-sm">
                                    {targetLabel?.label || placeholder}
                                </span>
                            </span>
                        )}
                        {!disabled && (
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-6">
                                <ChevronDownIcon
                                    className={clsx(
                                        'h-4 w-4',
                                        error && touched
                                            ? 'text-red-500'
                                            : 'text-secondary'
                                    )}
                                    aria-hidden="true"
                                />
                            </span>
                        )}
                    </Combobox.Button>
                    <Transition
                        as={Fragment}
                        leave="fixed transition ease-in duration-100 z-50"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        afterLeave={() => setQuery('')}
                    >
                        <Combobox.Options
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            static={staticOpen}
                            onBlur={onListBoxBlur}
                        >
                            {filteredOptions.length === 0 && query !== '' ? (
                                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                </div>
                            ) : (
                                filteredOptions.map((option) => (
                                    <Combobox.Option
                                        key={option.id}
                                        className={({ active }) =>
                                            `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                                active
                                                    ? 'bg-secondary text-white'
                                                    : 'text-gray-900'
                                            }`
                                        }
                                        value={option}
                                    >
                                        {(optionArg) => {
                                            const {
                                                active: isActive,
                                                selected: isSelected,
                                            } = optionArg;
                                            const optionLeadingResolved =
                                                optionLeading(
                                                    option,
                                                    optionArg
                                                );
                                            return (
                                                <>
                                                    <span
                                                        className={clsx(
                                                            isSelected
                                                                ? 'font-semibold'
                                                                : 'font-normal',
                                                            'block truncate text-xs md:text-sm'
                                                        )}
                                                    >
                                                        {option.label}
                                                    </span>
                                                    {optionLeadingResolved ? (
                                                        <span
                                                            className={clsx(
                                                                isActive
                                                                    ? 'text-white'
                                                                    : 'text-secondary',
                                                                'absolute inset-y-0 left-0 flex items-center pl-1 md:pl-1.5'
                                                            )}
                                                        >
                                                            {
                                                                optionLeadingResolved
                                                            }
                                                        </span>
                                                    ) : null}
                                                </>
                                            );
                                        }}
                                    </Combobox.Option>
                                ))
                            )}
                        </Combobox.Options>
                    </Transition>
                </div>
            </Combobox>
        </div>
    );
}

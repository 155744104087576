import * as Select from '@radix-ui/react-select';
import clsx from 'clsx';
import { useState } from 'react';
import { ChevronDownIcon } from '@/components/common/Icons';
import Option from '@/types/Option';

type DashboardSelectProps = {
    label?: string;
    placeholder?: string;
    value?: string;
    onChange(value: string): void;
    options?: Option[];
    rounded?: boolean;
    disabled?: boolean;
};

export default function DashboardSelect(props: DashboardSelectProps) {
    const { options, value, label, placeholder, onChange, rounded, disabled } =
        props;
    const [open, setOpen] = useState(false);
    return (
        <Select.Root
            open={open}
            onOpenChange={setOpen}
            value={value}
            onValueChange={onChange}
            disabled={disabled}
        >
            {label && <span className="text-sm">{label}</span>}
            <Select.Trigger
                className={clsx(
                    'inline-flex w-full items-center justify-between border bg-white py-2 px-5 text-xs focus:outline-none focus-visible:ring-1 focus-visible:ring-black sm:text-sm',
                    disabled ? 'border-gray-300 text-gray-300' : 'border-black',
                    rounded && 'rounded'
                )}
            >
                <Select.Value placeholder={placeholder} />
                <Select.Icon>
                    <ChevronDownIcon
                        className={clsx(disabled && 'text-gray-300')}
                        aria-hidden="true"
                    />
                </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
                <Select.Content className="h-fit w-full overflow-hidden rounded border border-black bg-white">
                    <Select.ScrollUpButton>
                        <ChevronDownIcon className="rotate-90" />
                    </Select.ScrollUpButton>
                    <Select.Viewport>
                        {options?.map((option) => (
                            <Select.Item
                                key={option.id}
                                className="relative flex w-full cursor-default items-center px-5 hover:bg-primary highlighted:outline highlighted:outline-1 highlighted:outline-primary"
                                value={option.value}
                            >
                                <Select.ItemText>
                                    {option.label}
                                </Select.ItemText>
                            </Select.Item>
                        ))}
                    </Select.Viewport>
                    <Select.ScrollDownButton>
                        <ChevronDownIcon />
                    </Select.ScrollDownButton>
                </Select.Content>
            </Select.Portal>
        </Select.Root>
    );
}

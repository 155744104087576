import { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';
import { useKlaviyoError } from '@/services/klaviyo';
import { useUser } from '@/store/user/userSlice';

type Props = {
    dashboard?: boolean;
};

export default function ErrorPage({ dashboard }: Props) {
    const [user] = useUser();
    const error = useRouteError() as Error;
    const klaviyoError = useKlaviyoError;
    useEffect(() => {
        if (user && error) {
            klaviyoError(user, error);
        }
    }, [user, error, klaviyoError]);

    return (
        <div className="flex min-h-full flex-col bg-white pt-16 pb-12">
            <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-4 sm:px-6 lg:px-8">
                <div className="py-16">
                    <div className="text-center">
                        <p className="text-base font-semibold text-primary">
                            Error
                        </p>
                        <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                            An error has occurred
                        </h1>
                        <p className="mt-2 text-base text-gray-500">
                            Please try again later, or{' '}
                            <a href="/support" className="underline">
                                contact us
                            </a>{' '}
                            if this issue persists.
                        </p>
                        {process.env.NODE_ENV === 'development' && (
                            <pre className="mt-8 overflow-x-auto rounded-md bg-gray-100 p-4 text-left text-xs text-red-800">
                                Uncaught {error.name}: {error.message}
                                <br />
                                {error.stack}
                            </pre>
                        )}
                        <div className="mt-6">
                            <a
                                href={dashboard ? '/dashboard' : '/'}
                                className="hover:text-primary-400 text-base font-medium text-primary"
                            >
                                {dashboard ? 'Back to dashboard' : 'Home page'}
                                <span aria-hidden="true"> &rarr;</span>
                            </a>
                        </div>
                    </div>
                </div>
            </main>
            <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
                <nav className="flex justify-center space-x-4">
                    <a
                        href="/"
                        className="text-sm font-medium text-gray-500 hover:text-gray-600"
                    >
                        Home page
                    </a>
                    <span
                        className="inline-block border-l border-gray-300"
                        aria-hidden="true"
                    />
                    <a
                        href="/contact-us"
                        className="text-sm font-medium text-gray-500 hover:text-gray-600"
                    >
                        Contact us
                    </a>
                    <span
                        className="inline-block border-l border-gray-300"
                        aria-hidden="true"
                    />
                    <a
                        href="/sign-in"
                        className="text-sm font-medium text-gray-500 hover:text-gray-600"
                    >
                        Sign in
                    </a>
                </nav>
            </footer>
        </div>
    );
}

import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../types';

export type GetStartedErrorState = {
    error: any;
    checkout: any;
    unauthenticated: any;
    unexpectedError: any;
};

const initialState: GetStartedErrorState = {
    error: null,
    checkout: false,
    unauthenticated: false,
    unexpectedError: false,
};

export const getStartedErrorSlice = createSlice({
    name: 'get-started-error',
    initialState,
    reducers: {
        resetCheckoutError: () => initialState,
        setError(state, { payload }: PayloadAction<any>) {
            state.error = payload;
        },
        setAttemptedCheckout(state, { payload }: PayloadAction<any>) {
            state.checkout = payload;
        },
        setUnauthenticated(state, { payload }: PayloadAction<any>) {
            state.unauthenticated = payload;
        },
        setUnexpectedError(state, { payload }: PayloadAction<any>) {
            state.unexpectedError = payload;
        },
    },
});

export default getStartedErrorSlice.reducer;

export const {
    setError,
    setAttemptedCheckout,
    setUnauthenticated,
    setUnexpectedError,
    resetCheckoutError,
} = getStartedErrorSlice.actions;

const selectSelf = (state: RootState) => state.getStartedError;

export const selectError = createSelector(selectSelf, (s) => s.error);
export const selectCheckout = createSelector(selectSelf, (s) => s.checkout);
export const selectUnauthenticated = createSelector(
    selectSelf,
    (s) => s.unauthenticated
);
export const selectUnexpectedError = createSelector(
    selectSelf,
    (s) => s.unexpectedError
);

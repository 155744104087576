import clsx from 'clsx';
import { FieldValidator, useField } from 'formik';
import { useContext } from 'react';
import { disableAutoCompleteProps } from '@/utils/inputs';
import { InputGroupContext } from './InputGroup';

export type TextInputProps = {
    id?: string;
    name?: string;
    placeholder?: string;
    autoComplete?: string;
    validate?: FieldValidator;
    rounded?: boolean;
    disabled?: boolean;
    maxLength?: number;
};

export default function TextArea(props: TextInputProps) {
    const {
        inputId: ctxId,
        name: ctxName,
        errorId,
    } = useContext(InputGroupContext);

    const {
        id = ctxId,
        name = ctxName,
        autoComplete,
        validate,
        placeholder,
        rounded,
        disabled,
        maxLength,
    } = props;

    const [field, meta] = useField({
        name,
        validate,
    });
    field.value = field.value ?? '';

    const { error, touched } = meta;
    return (
        <div className="relative mt-1">
            <textarea
                id={id}
                {...(autoComplete === 'off' ? disableAutoCompleteProps : {})}
                {...field}
                placeholder={placeholder}
                className={clsx(
                    'form-input min-h-[90px]',
                    error && touched && 'error',
                    rounded && 'rounded'
                )}
                aria-invalid={Boolean(error && touched)}
                aria-describedby={error && touched ? errorId : undefined}
                disabled={disabled}
                maxLength={maxLength}
            />
        </div>
    );
}

import Wrapper from './Wrapper';

const LogoWhite = `${window.cdn}logo-2-white.png`;

export default function GetStartedFooter() {
    return (
        <div className="bg-secondary py-4">
            <Wrapper className="items-center lg:flex lg:justify-between">
                <img
                    src={LogoWhite}
                    alt="ButcherCrowd"
                    className="mx-auto h-auto w-[154px] lg:mx-0 lg:pl-10"
                />
                <div className="inset-0 flex flex-col items-center py-4 text-center lg:flex-row">
                    <a
                        href="mailto:support@butchercrowd.com.au"
                        className="font-agrandir text-[10px] font-bold font-extrabold text-[#898989] transition-colors hover:text-surface sm:text-xs"
                    >
                        SUPPORT@BUTCHERCROWD.COM.AU
                    </a>
                    <div className="mx-4 mb-1 hidden w-6 border border-[#898989] text-white lg:block" />
                    <a
                        href="tel:1300998999"
                        className="pt-2 font-agrandir text-[10px] font-bold font-extrabold text-[#898989] transition-colors hover:text-surface sm:text-xs lg:pr-10 lg:pt-0"
                    >
                        P:1300 998 999
                    </a>
                </div>
            </Wrapper>
        </div>
    );
}

import { useMutation, useQuery } from '@tanstack/react-query';
import queryClient from '@/utils/queryClient';
import client from './client';

const { get } = client;

export type PaymentMethodsModel = any;

export const getPaymentMethods = () =>
    get<PaymentMethodsModel>('/api/me/payment-methods');

export function usePaymentMethod() {
    const query = useQuery(
        ['paymentsMethods'],
        async () => {
            const { data } = await getPaymentMethods();
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: paymentMethodsResult, ...queryResult } = query;
    return { paymentMethodsResult, ...queryResult };
}

export const createPaymentMethod = (data: any) =>
    client.post<any>(`/api/me/payment-methods`, data);

export function useCreatePaymentMethod() {
    return useMutation(
        async (payload: any) => {
            const { data } = await createPaymentMethod(payload);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.removeQueries(['paymentMethods']);
                queryClient.invalidateQueries(['paymentsMethods']);
            },
        }
    );
}

export const updatePaymentMethod = (id: string, data: any) =>
    client.put<any>(`/api/me/payment-methods/${id}`, data);

export function useUpdatePaymentMethod(id: string) {
    return useMutation(
        async (payload: any) => {
            const { data } = await updatePaymentMethod(id, payload);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.removeQueries(['paymentMethods']);
                queryClient.invalidateQueries(['paymentsMethods']);
            },
        }
    );
}

export const deletePaymentMethod = (id: string) =>
    client.delete<any>(`/api/me/payment-methods/${id}`);

export function useDeletePaymentMethod(navigate: any, id: string, toast: any) {
    return useMutation(
        async () => {
            navigate('/payment');
            const { data } = await deletePaymentMethod(id);
            return data;
        },
        {
            onSuccess: () => {
                queryClient.removeQueries(['paymentMethods']);
                queryClient.invalidateQueries(['paymentsMethods']);
                toast();
            },
        }
    );
}

export const setDefaultPaymentMethod = (subscription: any, data: any) =>
    client.put(`/api/subscriptions/${subscription}/payment-method`, data);

export function useSetDefaultPaymentMethod(
    subscription: any,
    payload: any,
    toast: any,
    setDefaultLoading: any
) {
    return useMutation(
        async () => {
            subscription
                ?.filter((e: any) => e.paymentMethod.id !== payload.id)
                .map(async (i: any) => {
                    await setDefaultPaymentMethod(i.id, payload);
                });
        },
        {
            onSuccess: () => {
                setTimeout(() => {
                    queryClient.invalidateQueries(['deliveryBox']);
                    toast();
                    setDefaultLoading(false);
                }, 2000);
            },
        }
    );
}

import { Helmet } from 'react-helmet';

type Props = {
    dashboard?: boolean;
};

export default function NotFoundPage({ dashboard }: Props) {
    return (
        <>
            <Helmet>
                <title>Page not found | ButcherCrowd</title>
                <meta
                    name="description"
                    content="Curated or customised meat & seafood boxes delivered for your everyday cooking essentials"
                />
                <meta
                    property="og:title"
                    content="Page not found | ButcherCrowd"
                />
            </Helmet>
            <div className="flex flex-col min-h-full pt-16 pb-12 bg-white">
                <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="py-16">
                        <div className="text-center">
                            <p className="text-base font-semibold text-primary">
                                404
                            </p>
                            <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                Not Found
                            </h1>
                            <p className="mt-2 text-base text-gray-500">
                                This page does not exist
                            </p>
                            <div className="mt-6">
                                <a
                                    href={dashboard ? '/dashboard' : '/'}
                                    className="text-base font-medium hover:text-primary-400 text-primary"
                                >
                                    {dashboard
                                        ? 'Back to dashboard'
                                        : 'Home page'}
                                    <span aria-hidden="true"> &rarr;</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </main>
                <footer className="flex-shrink-0 w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <nav className="flex justify-center space-x-4">
                        <a
                            href="/"
                            className="text-sm font-medium text-gray-500 hover:text-gray-600"
                        >
                            Home page
                        </a>
                        <span
                            className="inline-block border-l border-gray-300"
                            aria-hidden="true"
                        />
                        <a
                            href="/contact-us"
                            className="text-sm font-medium text-gray-500 hover:text-gray-600"
                        >
                            Contact us
                        </a>
                        <span
                            className="inline-block border-l border-gray-300"
                            aria-hidden="true"
                        />
                        <a
                            href="/sign-in"
                            className="text-sm font-medium text-gray-500 hover:text-gray-600"
                        >
                            Sign in
                        </a>
                    </nav>
                </footer>
            </div>
        </>
    );
}

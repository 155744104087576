import { getTimeUtc } from '@/utils/luxonFormats';

export default function useInitialDeliveryTimeIndex(deliveryTime: any) {
    if (!deliveryTime) return 0;
    for (let i = 0; i < deliveryTime?.length; i += 1) {
        if (
            getTimeUtc(`${deliveryTime[i].date}T${deliveryTime[i].start}Z`) ===
            '08:00:00'
        ) {
            return i;
        }
    }
    return 0;
}

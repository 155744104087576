import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../types';

export type GetStartedLoadingState = {
    loading: any;
};

const initialState: GetStartedLoadingState = {
    loading: false,
};

export const getStartedLoadingSlice = createSlice({
    name: 'get-started-loading',
    initialState,
    reducers: {
        setLoading(state, { payload }: PayloadAction<any>) {
            state.loading = payload;
        },
    },
});

export default getStartedLoadingSlice.reducer;

export const { setLoading } = getStartedLoadingSlice.actions;

const selectSelf = (state: RootState) => state.getStartedLoading;

export const selectLoading = createSelector(selectSelf, (s) => s.loading);

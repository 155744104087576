/* eslint-disable import/prefer-default-export */
/**
 * Defines a set of props for inputs that disable most password managers.
 * As of 19-08-2022 works for Dashlane, 1Password
 */
export const disableAutoCompleteProps = {
    autoComplete: 'off',
    'data-lpignore': 'true',
    'data-form-type': 'other',
} as const;

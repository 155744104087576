import React from 'react';

type ComposeItem<P = {}> = [React.JSXElementConstructor<P>, P?];

interface ComposeProps {
    items: ComposeItem<any>[];
}

export default function Compose({
    items,
    children,
}: React.PropsWithChildren<ComposeProps>) {
    return items.reduceRight(
        (acc, [Provider, props = {}]) =>
            React.createElement(Provider, props, acc),
        children
    ) as JSX.Element;
}

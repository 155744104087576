import { useQuery } from '@tanstack/react-query';
import client from './client';

const { get, patch } = client;

export type ModifyProfileParams = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
};

export const patchModifyProfile = (data: ModifyProfileParams) =>
    patch('/api/me/information', data);

export const getCreditBalance = () => get<any>('/api/credits/balance');

export function useCreditBalance(fetch: boolean) {
    const query = useQuery(
        ['creditBalance'],
        async () => {
            const { data } = await getCreditBalance();
            return data;
        },
        {
            retry: false,
            enabled: fetch,
        }
    );
    const { data: creditBalanceResult, ...queryResult } = query;
    return { creditBalanceResult, ...queryResult };
}

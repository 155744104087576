import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/types';
import {
    decrementItem,
    selectItemCount,
    selectTotalCount,
    setItemCount,
} from '@/store/yourItems/yourItemsSlice';
import ProductCountInput from '../inputs/ProductCountInput';
import { SmallXIcon } from './Icons';

type Props = {
    id: string;
    name: string;
    thumbnail: string;
    maxCount: number;
    inventory?: number | boolean;
    maxSelection?: number;
    locked?: boolean;
    lockedCount?: number;
    error?: boolean;
    removeCart?: any;
    product?: any;
};

export default function SummaryItem({
    id,
    name,
    thumbnail,
    maxCount,
    inventory,
    maxSelection,
    locked,
    lockedCount,
    error,
    removeCart,
    product,
}: Props) {
    const count = useSelector((state: RootState) => selectItemCount(state, id));
    const totalCount: any = useSelector(selectTotalCount);
    const dispatch = useDispatch();
    return (
        <div className="flex items-start" key={id}>
            <img
                src={thumbnail}
                alt=""
                className={clsx(
                    'aspect-square object-cover object-center',
                    locked ? 'h-12 w-12' : 'h-20 w-20'
                )}
            />
            {locked && (
                <div className="flex aspect-square h-8 w-8 items-center justify-center self-center text-center font-medium">
                    <span>{lockedCount}</span>
                </div>
            )}
            <div className="flex max-w-[100%_-_5rem] grow flex-col space-y-1 self-center">
                <span className="px-2 text-xs font-medium uppercase tracking-wide">
                    {name}
                </span>
                {!locked && (
                    <ProductCountInput
                        id={id}
                        count={count}
                        available={maxCount - totalCount}
                        simple
                        maxSelection={maxSelection}
                        onCountChange={(value) =>
                            dispatch(setItemCount({ id, count: value }))
                        }
                        removeCart={removeCart}
                        product={product}
                    />
                )}
                {error &&
                    inventory !== null &&
                    (count > maxSelection! || count > (inventory as any)) && (
                        <div className="ml-2 text-xs text-error">
                            There&apos;s not enough inventory for the selected
                            amount.
                        </div>
                    )}
            </div>
            {!locked && (
                <button type="button">
                    <SmallXIcon
                        className="mx-2 h-7 w-7 shrink-0 p-2"
                        onClick={() => {
                            dispatch(decrementItem({ id }));
                            if (removeCart && product) {
                                removeCart(product, count);
                            }
                        }}
                    />
                </button>
            )}
        </div>
    );
}

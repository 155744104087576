import { useMemo } from 'react';
import {
    FieldHelperProps,
    FieldMetaProps,
    FieldValidator,
    useField,
} from 'formik';
import hash_sum from 'hash-sum';
import Option from '@/types/Option';
import useRefStableCallback from './useRefStableCallback';

type OptionsConfig<T, M> = {
    name: string;
    options: Option<T>[];
    multiple?: M;
    validate?: FieldValidator;
};

type OptionsResult<T, M> = M extends true
    ? {
          selected?: Option<T>[];
          setSelected(option: Option<T>[]): void;
      }
    : M extends false
    ? {
          selected?: Option<T>;
          setSelected(option: Option<T>): void;
      }
    : {
          selected?: Option<T> | Option<T>[];
          setSelected(option: Option<T> | Option<T>[]): void;
      };

export default function useOptionsField<
    T = string,
    M extends boolean | undefined = undefined
>(
    config: OptionsConfig<T, M>
): [OptionsResult<T, M>, FieldMetaProps<T | T[]>, FieldHelperProps<T | T[]>] {
    const { name, multiple, validate, options } = config;

    const [, meta, helpers] = useField({
        name,
        validate,
    });

    const { value } = meta;
    const { setValue } = helpers;

    const arrayValue = useMemo(
        () =>
            multiple || !value
                ? (value as Option<T>[]) || []
                : [value as Option<T>],
        [value, multiple]
    );
    const selectedIds = useMemo(
        () => arrayValue.map((s) => s.id),
        [arrayValue]
    );
    const selectedIdsHash = hash_sum(selectedIds);
    const optionById = useMemo(
        () =>
            options.reduce((obj: any, opt: Option<T>) => {
                obj[opt.id] = opt;
                return obj;
            }, {}),
        [options]
    );
    const selected = useMemo(
        () => {
            if (multiple)
                return selectedIds.map((id) => optionById[id]).filter(Boolean);
            if (!selectedIds.length) return undefined;
            return optionById[selectedIds[0]];
        },
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        [selectedIdsHash, optionById, multiple]
    );

    const setSelected = useRefStableCallback(
        multiple
            ? (sel: Option<T>[]) => {
                  // Check if any options are exclusive and were not already selected.
                  const exclusiveOption = sel
                      .filter((o) => !selected.includes(o))
                      .find((o) => o.exclusive);
                  // Exclusive options clears all other options.
                  if (exclusiveOption) {
                      setValue([exclusiveOption]);
                      return;
                  }
                  // Set any value that is not exclusive.
                  setValue(sel.filter((o) => !o.exclusive));
              }
            : (sel: Option<T>) => {
                  setValue(sel);
              }
    );

    const optionsResult = useMemo<OptionsResult<T, M>>(
        () =>
            ({
                selected,
                setSelected,
            } as any),
        [selected, setSelected]
    );

    return [optionsResult, meta, helpers];
}

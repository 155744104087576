export const menuWeekDate = {
    format(date: Date) {
        return new Intl.DateTimeFormat('en-AU', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
        })
            .format(date)
            .replace(/,/g, '');
    },
};

export const time = new Intl.DateTimeFormat('en-AU', {
    timeZone: 'Australia/Sydney',
    hour: 'numeric',
    minute: 'numeric',
});

export const fullDate = new Intl.DateTimeFormat('en-AU', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Australia/Sydney',
});

export const shortDate = new Intl.DateTimeFormat('en-AU', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'Australia/Sydney',
});

export const shorterDate = new Intl.DateTimeFormat('en-AU', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    timeZone: 'Australia/Sydney',
});

export const longDate = new Intl.DateTimeFormat('en-AU', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'Australia/Sydney',
});

export const monthDate = new Intl.DateTimeFormat('en-AU', {
    month: 'long',
    day: 'numeric',
    timeZone: 'Australia/Sydney',
});

export const monthDateYear = new Intl.DateTimeFormat('en-AU', {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'Australia/Sydney',
});

export const shorterTime = new Intl.DateTimeFormat('en-AU', {
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Australia/Sydney',
});

export const shortNumericDate = new Intl.DateTimeFormat('en-AU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'Australia/Sydney',
});

export function formatToUtc4(date: any) {
    const newDate = new Date(date);
    newDate.setUTCHours(4);
    newDate.setUTCMinutes(0);
    newDate.setUTCSeconds(0);
    const utc4DateString = newDate.toISOString().replace('T', ' ').slice(0, -5);
    return utc4DateString;
}

export function parseDate(date: any) {
    const parts = date.match(/(\d{4})-(\d{2})-(\d{2})/);
    return new Date(parts[1], parts[2] - 1, parts[3]);
}

import { useMutation, useQuery } from '@tanstack/react-query';
import { decamelize } from 'humps';
import DeliveryScheduleModel from '@/models/DeliveryScheduleModel';
import ProductModel from '@/models/ProductModel';
import client from './client';

const { get } = client;

type GetStartedParams = {
    email: string;
    shippingPostcode: string;
    deliveryScheduleId: string;
    shippingStartedAt: string;
};

type ModifyGetStartedParams = {
    shippingPostcode: string;
    deliveryScheduleId: string;
    shippingStartedAt: string;
};

type ModifyGetStartedPackagesParams = {
    root?: string;
    pivot?: string;
    package?: string;
    frequency?: number;
};

type ModifySubscriptionOfferParams = {
    code: string;
};

type GetStartedCheckoutParams = any;

export const getDeliverySchedule = (postcode: string) =>
    get<DeliveryScheduleModel[]>(
        `/api/delivery-zones/${postcode}/schedules?backoff=1`
    );

export const getPackages = (subscription: string) =>
    client.get<any>(`/api/subscriptions/${subscription}/packages`);

export function usePackages(subscription: string, fetch: boolean) {
    const query = useQuery(
        ['packages'],
        async () => {
            const { data } = await getPackages(subscription);
            return data;
        },
        {
            retry: false,
            enabled: fetch,
        }
    );

    const { data: packagesResult, ...queryResult } = query;
    return { packagesResult, ...queryResult };
}

export const getChildPackages = (subscription: string, id: string) =>
    client.get<any>(`/api/subscriptions/${subscription}/packages/${id}`);

export function useChildPackages(subscription: string, id: string) {
    const query = useQuery(
        ['childpackages', id],
        async () => {
            const { data } = await getChildPackages(subscription, id);
            return data;
        },
        {
            retry: false,
        }
    );

    const { data: childPackagesResult, ...queryResult } = query;
    return { childPackagesResult, ...queryResult };
}

export const getDeliveryFrequency = (subscription: string) =>
    client.get<any>(`/api/subscriptions/${subscription}/frequencies`);

export function useDeliveryFrequency(subscription: string, fetch: boolean) {
    const query = useQuery(
        ['deliveryfrequency'],
        async () => {
            const { data } = await getDeliveryFrequency(subscription);
            return data;
        },
        {
            retry: false,
            enabled: fetch,
        }
    );

    const { data: deliveryFrequencyResult, ...queryResult } = query;
    return { deliveryFrequencyResult, ...queryResult };
}

export const getProductsAddons = (subscription: string) =>
    client.get<ProductModel[]>(`/api/subscriptions/${subscription}/add-ons`);

export function useProductsAddons(subscription: string) {
    const query = useQuery(
        ['productsaddons'],
        async () => {
            const { data } = await getProductsAddons(subscription);
            return data;
        },
        {
            retry: false,
        }
    );

    const { data: productsAddonsResult, ...queryResult } = query;
    return { productsAddonsResult, ...queryResult };
}

export const getProducts = (subscription: string) =>
    client.get<ProductModel[]>(`/api/subscriptions/${subscription}/products`);

export const getPackageProduct = (
    subscription: string,
    subscriptionPackage: string
) =>
    client.get<ProductModel[]>(
        `/api/subscriptions/${subscription}/packages/${subscriptionPackage}/products`
    );

export const getPackageProductWithoutSubscription = (
    subscriptionPackage: string
) => client.get<any>(`/api/packages/${subscriptionPackage}`);

export function useProducts(subscription: string, packageId: string) {
    const query = useQuery(
        ['products', packageId],
        async () => {
            if (packageId) {
                const { data } = await getPackageProduct(
                    subscription,
                    packageId
                );
                return data;
            }
            const { data } = await getProducts(subscription);
            return data;
        },
        {
            retry: false,
        }
    );

    const { data: productsResult, ...queryResult } = query;
    return { productsResult, ...queryResult };
}

export function useProductsPackagesWithoutSubscription(
    packageId: string,
    childPackagesName: string
) {
    const query = useQuery(
        ['products', packageId],
        async () => {
            const { data } = await getPackageProductWithoutSubscription(
                packageId
            );

            return data;
        },
        {
            enabled: childPackagesName !== 'Custom Box',
            retry: false,
        }
    );

    const { data: productsPackagesResult, ...queryResult } = query;
    return { productsPackagesResult, ...queryResult };
}

export function useProductsPackages(
    subscription: string,
    packageId: string,
    childPackagesName: string
) {
    const query = useQuery(
        ['products', packageId],
        async () => {
            const { data } = await getPackageProduct(subscription, packageId);

            return data;
        },
        {
            enabled: childPackagesName !== 'Custom Box',
            retry: false,
        }
    );

    const { data: productsPackagesResult, ...queryResult } = query;
    return { productsPackagesResult, ...queryResult };
}

export const subscribeProduct = (subscription: string, data: any) =>
    client.patch<any>(
        window.Echo.socketId()
            ? `/api/subscriptions/${subscription}/choices`
            : `/api/socketless/subscriptions/${subscription}/choices`,
        data
    );

export function useSubscribeProduct(subscription: string, payload: any) {
    return useMutation(
        async () => {
            const { data } = await subscribeProduct(subscription, payload);
            return data;
        },
        {
            onSuccess: () => {
                if (!window.Echo.socketId()) {
                    window.location.href = '/get-started/addons';
                }
            },
        }
    );
}

export const subscribeAddons = (subscription: string, data: any) =>
    client.patch<any>(
        window.Echo.socketId()
            ? `/api/subscriptions/${subscription}/add-ons`
            : `/api/socketless/subscriptions/${subscription}/add-ons`,
        data
    );

export function useSubscribeAddons(subscription: string, payload: any) {
    return useMutation(async () => {
        const { data } = await subscribeAddons(subscription, payload);
        return data;
    });
}

export const modifyPackages = (
    subscription: string,
    data: ModifyGetStartedPackagesParams
) =>
    client.patch<ModifyGetStartedPackagesParams | any>(
        window.Echo.socketId()
            ? `/api/subscriptions/${subscription}/packages`
            : `/api/socketless/subscriptions/${subscription}/packages`,
        data
    );

export function useModifyPackages(subscription: string, payload: any) {
    return useMutation(async () => {
        const { data } = await modifyPackages(subscription, payload);
        return data;
    });
}

export const subscribeGetStarted = (data: GetStartedParams) =>
    client.post<GetStartedParams | any>('/create-account', data);

export function useSubscribeGetStarted() {
    return useMutation(async (payload: GetStartedParams) => {
        if (payload.shippingPostcode?.charAt(0) === '0') {
            payload.shippingPostcode = payload.shippingPostcode?.substring(1);
        }
        const { data } = await subscribeGetStarted(payload);
        return data;
    });
}

export const modifyGetStarted = (
    subscription: string,
    data: ModifyGetStartedParams
) =>
    client.patch<ModifyGetStartedParams | any>(
        `/api/subscriptions/${subscription}/shipping-postcode`,
        data
    );

export const modifySubscriptionOffer = (
    subscription: string,
    data: ModifySubscriptionOfferParams
) =>
    client.put<ModifySubscriptionOfferParams | any>(
        `/api/subscriptions/${subscription}/offer`,
        data
    );

export function useModifyGetStarted(subscription: string) {
    return useMutation(async (payload: ModifyGetStartedParams) => {
        if (payload.shippingPostcode?.charAt(0) === '0') {
            payload.shippingPostcode = payload.shippingPostcode?.substring(1);
        }
        const { data } = await modifyGetStarted(subscription, payload);
        return data;
    });
}

export const newSubscriptionGetStarted = (data: ModifyGetStartedParams) =>
    client.post<ModifyGetStartedParams | any>(`/api/subscriptions`, data);

export function useNewSubcriptionGetStarted() {
    return useMutation(async (payload: ModifyGetStartedParams) => {
        if (payload.shippingPostcode?.charAt(0) === '0') {
            payload.shippingPostcode = payload.shippingPostcode?.substring(1);
        }
        const { data } = await newSubscriptionGetStarted(payload);
        return data;
    });
}

export const modifyGetStartedPackages = (
    subscription: string,
    data: ModifyGetStartedPackagesParams
) =>
    client.patch<ModifyGetStartedPackagesParams | any>(
        `/api/subscriptions/${subscription}/packages`,
        data
    );

export function useModifyGetStartedPackages(subscription: string) {
    return useMutation(async (payload: ModifyGetStartedPackagesParams) => {
        const { data } = await modifyGetStartedPackages(subscription, payload);
        return data;
    });
}

export const getStartedCart = (subscription: string) =>
    client.get<any>(`/api/subscriptions/${subscription}/checkout/breakdown`);

export function useGetStartedCart(subscription: string, fetch: boolean) {
    const query = useQuery(
        ['cart'],
        async () => {
            const { data } = await getStartedCart(subscription);
            return data;
        },
        {
            retry: false,
            enabled: fetch,
        }
    );

    const { data: cartResult, ...queryResult } = query;
    return { cartResult, ...queryResult };
}

export const getSuburbsMethod = (postcode: string) =>
    get<any[]>(`/api/delivery-zones/${postcode}/suburbs`);

export const getStartedCheckout = (
    subscription: string,
    data: GetStartedCheckoutParams
) =>
    client.post<GetStartedCheckoutParams | any>(
        window.Echo.socketId()
            ? `/api/subscriptions/${subscription}/checkout`
            : `/api/socketless/subscriptions/${subscription}/checkout`,
        data,
        {
            transformRequest: [
                (d: any) => {
                    const payload: any = new FormData();
                    Object.entries(d).forEach(([key, value]) => {
                        if (key === 'number' && value) {
                            payload.append('card[number]', value);
                        } else if (key === 'month' && value) {
                            payload.append('card[month]', value);
                        } else if (key === 'year' && value) {
                            payload.append('card[year]', value);
                        } else if (key === 'cvc' && value) {
                            payload.append('card[cvc]', value);
                        } else if (key === 'sms' && value) {
                            payload.append(decamelize(key), +value);
                        } else if (key === 'newsletter' && value) {
                            payload.append(decamelize(key), +value);
                        } else if (key === 'choices' && value) {
                            (value as any).forEach((e: any, i: any) => {
                                payload.append(
                                    `choices[${i}][id]`,
                                    e.product_id
                                );
                                payload.append(
                                    `choices[${i}][quantity]`,
                                    e.quantity
                                );
                            });
                        } else if (
                            value !== undefined &&
                            value !== false &&
                            value !== ''
                        ) {
                            payload.append(decamelize(key), value);
                        }
                    });
                    return payload;
                },
            ],
        }
    );

export function useGetStartedCheckout(subscription: string) {
    return useMutation(async (payload: GetStartedCheckoutParams) => {
        const { data } = await getStartedCheckout(subscription, payload);
        return data;
    });
}

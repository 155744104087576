import { ref, TestConfig } from 'yup';

export default function passwordConfirmation(
    field: string,
    message: string
): TestConfig<string | undefined> {
    return {
        name: 'passwordConfirmation',
        test(this, value?: string) {
            return this.resolve(ref(field)) === value;
        },
        message,
    };
}

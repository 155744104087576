import clsx from 'clsx';

type Props = {
    children: JSX.Element | JSX.Element[];
    className?: string;
};

export default function Wrapper({ children, className }: Props) {
    return (
        <div className={clsx('mx-auto w-full max-w-[1512px]', className)}>
            {children}
        </div>
    );
}

import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import formatDollarAmount from '@/utils/formatDollarAmount';

interface BillingDetailsCardProps {
    name: string;
    price?: number;
    discount?: any;
    primary?: any;
}

export default function BillingDetailsCard({
    name,
    price,
    discount,
    primary,
}: PropsWithChildren<BillingDetailsCardProps>) {
    return (
        <div
            className={clsx(
                'grid grid-cols-2 gap-1',
                primary && 'text-primary'
            )}
        >
            <span className="text-base font-medium uppercase">{name}</span>
            {price! >= 0 && (
                <span className="text-end text-base font-bold">
                    {formatDollarAmount(price!)}
                </span>
            )}
            {discount && (
                <span className="text-end text-base font-bold">
                    {formatDollarAmount(discount)}
                </span>
            )}
        </div>
    );
}

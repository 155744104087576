import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../types';

const initialState: any = {
    success: false,
};

export const giftCardsSuccessSlice = createSlice({
    name: 'gift-cards-success',
    initialState,
    reducers: {
        resetGiftCardsSuccess: () => initialState,
        setSuccess(state, { payload }: PayloadAction<any>) {
            state.success = payload;
        },
    },
});

export default giftCardsSuccessSlice.reducer;

export const { setSuccess, resetGiftCardsSuccess } =
    giftCardsSuccessSlice.actions;

const selectSelf = (state: RootState) => state.giftCardsSuccess;

export const selectSuccess = createSelector(selectSelf, (s) => s.success);

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/store/types';
import {
    selectItemCount,
    selectTotalCount,
    setItemCount,
} from '@/store/yourItems/yourItemsSlice';
import ProductCountInput from '../inputs/ProductCountInput';

type Props = {
    itemId: string;
    name: string;
    thumbnail: string;
    maxCount: number;
    maxSelection?: number | boolean;
    quantity?: number;
};

export default function PromotionSummaryItem({
    itemId,
    name,
    thumbnail,
    maxCount,
    maxSelection,
    quantity,
}: Props) {
    const count = useSelector((state: RootState) =>
        selectItemCount(state, itemId)
    );
    const totalCount: any = useSelector(selectTotalCount);
    const dispatch = useDispatch();

    return (
        <div
            className="flex min-h-fit items-start border-b border-secondary py-1"
            key={itemId}
        >
            <div className="relative">
                <img
                    src={thumbnail}
                    alt=""
                    className="aspect-square h-20 w-20 max-w-none object-cover object-center"
                />
                {quantity && quantity > 1 && (
                    <span className="absolute top-1 left-1 rounded-md border border-solid border-primary bg-primary py-1 px-1.5 text-center text-xs leading-[normal] text-white md:px-2">
                        {quantity}
                    </span>
                )}
            </div>

            <div className="flex grow flex-col space-y-1 self-center">
                <span className="px-2 text-xs font-medium uppercase tracking-wide">
                    {name}
                </span>
                <ProductCountInput
                    id={itemId}
                    count={count || 0}
                    available={maxCount - totalCount}
                    simple
                    maxSelection={maxSelection}
                    onCountChange={(value) =>
                        dispatch(setItemCount({ id: itemId, count: value }))
                    }
                />
            </div>
        </div>
    );
}

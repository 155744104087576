const formatPlaceAddress = (place: any) => {
    const address: any = {};
    if (place && place.address_components) {
        const { address_components: addressComponents } = place;
        addressComponents?.forEach((e: any) => {
            if (e.types.includes('street_number')) {
                address.addressLine_1 = e.long_name;
            } else if (e.types.includes('subpremise')) {
                address.suite = e.long_name;
            } else if (e.types.includes('route')) {
                if (address.addressLine_1) {
                    address.addressLine_1 += ` ${e.long_name}`;
                } else {
                    address.addressLine_1 = e.long_name;
                }
            } else if (e.types.includes('locality')) {
                address.city = e.long_name;
            } else if (e.types.includes('administrative_area_level_1')) {
                address.state = e.short_name;
            } else if (e.types.includes('postal_code')) {
                address.postalCode = e.short_name;
            }
        });
    }
    return address;
};

export default formatPlaceAddress;

import type { TestConfig } from 'yup';

export default function expiration(): TestConfig<string | undefined> {
    return {
        name: 'expiration',
        test: (value) => {
            const today = new Date();
            const ccExpiration =
                value?.replace(/\//g, '')?.length === 3
                    ? new Date(
                          `${String(today.getFullYear()).slice(0, 2)}${value
                              ?.replace(/\//g, '')
                              .slice(1)}-${value
                              ?.replace(/\//g, '')
                              .slice(0, 1)}-01`
                      )
                    : new Date(
                          `${String(today.getFullYear()).slice(0, 2)}${value
                              ?.replace(/\//g, '')
                              ?.slice(2)}-${value
                              ?.replace(/\//g, '')
                              ?.slice(0, 2)}-01`
                      );
            return ccExpiration > today;
        },
        message: 'The card is expired.',
    };
}

import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';
// import LaravelError from '@/models/LaravelError';
import LaravelResponse from '@/models/LaravelResponse';

const client = axios.create({
    timeout: 30000,
    transformRequest: [(data) => JSON.stringify(decamelizeKeys(data))],
    transformResponse: [
        (data, headers) => {
            try {
                if (headers && headers['content-type'] === 'application/json') {
                    const parsed = camelizeKeys(
                        JSON.parse(data)
                    ) as LaravelResponse;
                    // If there are errors, throw a LaravelError.
                    if (parsed.errors) {
                        return camelizeKeys(JSON.parse(data));
                        // throw new LaravelError(parsed.message, parsed.errors);
                    }
                    // If there is data, return it.
                    if (parsed.data) {
                        return parsed.data;
                    }
                    // If there is no data, return the message, if any.
                    if (parsed.message) {
                        return parsed.message;
                    }
                    return parsed;
                }
            } catch {
                /* eslint-disable-next-line no-console */
                console.warn('Invalid json in response.', data);
            }
            return data;
        },
    ],
});

client.defaults.headers.common.Accept = 'application/json';
client.defaults.headers.common.charset = 'utf-8';
client.defaults.headers.post['Content-Type'] = 'application/json';
client.defaults.headers.put['Content-Type'] = 'application/json';
client.defaults.headers.patch['Content-Type'] = 'application/json';

export default client;

import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@/components/common/Button';
import { ArrowRightIcon } from '@/components/common/Icons';
import { resetItems } from '@/store/yourItems/yourItemsSlice';
import BillingProductListingCard from '../cards/BillingProductListingCard';

type Props = {
    data: any;
    simpleStyle?: boolean;
    discountCode: any;
    setDiscountCode(value: string): void;
    onClick?(value: string): void;
    error: any;
};

function getOrderValue(promotionOrder: any, valueOrder: any) {
    if (!valueOrder && promotionOrder > 0) {
        return promotionOrder;
    }
    return valueOrder;
}

function checkAvailableMenuWithPromotion(promotion: any) {
    if (promotion.orders === null || promotion.orders === 1) {
        return true;
    }
    return promotion.items.every((item: any) => item.order > promotion.orders);
}

function promotionItemsSortByOrder(a: any, b: any) {
    if (a.order !== null && b.order === null) {
        return -1;
    }
    if (a.order === null && b.order !== null) {
        return 1;
    }
    return 0;
}

const accumulateQuantities = (data: any) => {
    const map = new Map();

    if (!data) return null;

    data.forEach(
        (item: {
            product: { id: any };
            quantity: any;
            order: any;
            minimum: any;
        }) => {
            if (
                (item.order === 1 || item.order === null) &&
                (item.minimum === null || item.minimum === 1)
            ) {
                const productId = item.product.id;
                if (map.has(productId)) {
                    map.get(productId).quantity += item.quantity;
                } else {
                    map.set(productId, { ...item });
                }
            }
        }
    );

    return Array.from(map.values());
};

export default function PromoCodeInput({
    data,
    simpleStyle,
    discountCode,
    setDiscountCode,
    onClick,
    error,
}: Props) {
    const dispatch = useDispatch();
    const promotion = data?.data;
    const items = accumulateQuantities(promotion?.items);
    const discount = promotion?.values;
    const [code, setCode] = useState('');

    useEffect(() => {
        if (discountCode !== null) {
            setCode(discountCode);
        }
    }, [discountCode]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            dispatch(resetItems());
            setDiscountCode(code.toUpperCase());
        }, 1000);

        return () => clearTimeout(timeoutId);
    }, [code, discountCode, dispatch, setDiscountCode]);

    return (
        <div>
            <div
                className={clsx(
                    'relative',
                    simpleStyle
                        ? 'flex w-full'
                        : 'rounded-lg border border-gray-200 bg-white py-3 px-3'
                )}
            >
                <div
                    className={clsx(
                        'relative mt-1',
                        simpleStyle && 'w-[calc(100%_-_38px)]'
                    )}
                >
                    <input
                        type="text"
                        placeholder="Enter promo code"
                        className={clsx(
                            'form-input',
                            !simpleStyle && 'rounded'
                        )}
                        value={code?.toUpperCase()}
                        onChange={(event) => {
                            setCode(event.target.value);
                        }}
                    />
                </div>
                {onClick ? (
                    <Button
                        size="sm"
                        type="button"
                        disabled={code === ''}
                        onClick={() => {
                            onClick(code);
                        }}
                        className={clsx(
                            'flex items-center justify-center',
                            simpleStyle
                                ? 'mt-1 h-[34px] w-[34px] !bg-dashboard !p-0 transition-colors hover:!bg-primary sm:h-[38px] sm:w-[38px]'
                                : 'absolute top-[16px] right-[12px] h-[34px] w-[70px] rounded !bg-dashboard !font-neue font-medium normal-case !tracking-normal sm:top-[13px] sm:my-1 sm:h-[36px]'
                        )}
                    >
                        <ArrowRightIcon />
                    </Button>
                ) : (
                    <Button
                        size="sm"
                        type="button"
                        disabled={code === ''}
                        onClick={() => {
                            dispatch(resetItems());
                            setDiscountCode(code.toUpperCase());
                        }}
                        className={clsx(
                            'flex items-center justify-center',
                            simpleStyle
                                ? 'mt-1 h-[34px] w-[34px] !bg-dashboard !p-0 transition-colors hover:!bg-primary sm:h-[38px] sm:w-[38px]'
                                : 'absolute top-[16px] right-[12px] h-[34px] w-[70px] rounded !bg-dashboard !font-neue font-medium normal-case !tracking-normal sm:top-[13px] sm:my-1 sm:h-[36px]'
                        )}
                    >
                        <ArrowRightIcon />
                    </Button>
                )}
            </div>
            {error !== null && (
                <div className="mt-1 text-xs text-error">
                    Promo code is invalid
                </div>
            )}
            {promotion && !promotion?.usable && (
                <div className="mt-1 text-xs text-error">
                    Promo code is not usable
                </div>
            )}
            {!onClick && (
                <div>
                    {promotion?.usable && (
                        <div>
                            <div className="mt-3 text-xs font-bold">
                                Promo details
                            </div>
                            <div
                                role="separator"
                                className="my-2 h-[1px] bg-secondary"
                            />
                            {promotion?.orders &&
                            promotion?.items?.length > 0 &&
                            checkAvailableMenuWithPromotion(promotion) ? (
                                <div className="mt-2 text-xs">
                                    Free product(s) for {promotion.orders} order
                                    {promotion.orders > 1 ? 's' : ''}
                                </div>
                            ) : (
                                <div className="mb-2 space-y-1.5">
                                    {promotion?.items
                                        ?.sort(promotionItemsSortByOrder)
                                        ?.map((e: any) => {
                                            if (!e.choice) {
                                                if (e.order === 1) {
                                                    return (
                                                        <div key={e.id}>
                                                            <div className="mb-2 text-xs">
                                                                {e?.quantity}{' '}
                                                                free{' '}
                                                                {
                                                                    e?.product
                                                                        ?.name
                                                                }{' '}
                                                                {e?.repeat
                                                                    ? 'every order'
                                                                    : 'on this order'}{' '}
                                                                {e?.minimum
                                                                    ? `from order #${e.minimum}`
                                                                    : ''}
                                                            </div>
                                                            {promotion?.items
                                                                ?.length >
                                                                1 && (
                                                                <span className="text-xs uppercase">
                                                                    Plus
                                                                </span>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                                if (e.order > 1) {
                                                    return (
                                                        <div key={e.id}>
                                                            <div className="mb-2 text-xs">
                                                                {e?.quantity}{' '}
                                                                free{' '}
                                                                {
                                                                    e?.product
                                                                        ?.name
                                                                }{' '}
                                                                {e?.repeat
                                                                    ? `every ${e.order} orders`
                                                                    : `on order #${e.order}`}{' '}
                                                                {e?.minimum
                                                                    ? `from order #${e.minimum}`
                                                                    : ''}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                if (promotion.orders > 0) {
                                                    return (
                                                        <div
                                                            className="text-xs"
                                                            key={e.id}
                                                        >
                                                            {e?.quantity} free{' '}
                                                            {e?.product?.name}{' '}
                                                            on{' '}
                                                            {e?.minimum
                                                                ? `For ${
                                                                      promotion.orders
                                                                  } ${
                                                                      promotion.orders >
                                                                      1
                                                                          ? 'orders'
                                                                          : 'order'
                                                                  } from box #${
                                                                      e.minimum
                                                                  }`
                                                                : `${
                                                                      promotion.orders
                                                                  } ${
                                                                      promotion.orders >
                                                                      1
                                                                          ? 'orders'
                                                                          : 'order'
                                                                  }`}
                                                        </div>
                                                    );
                                                }
                                                if (e.order === null) {
                                                    return (
                                                        <div
                                                            className="text-xs"
                                                            key={e.id}
                                                        >
                                                            {e?.quantity} free{' '}
                                                            {e?.product?.name}{' '}
                                                            {e?.repeat
                                                                ? 'every order'
                                                                : 'on this order'}{' '}
                                                            {e?.minimum
                                                                ? `from box #${e.minimum}`
                                                                : ''}
                                                        </div>
                                                    );
                                                }

                                                return (
                                                    <div
                                                        className="text-xs"
                                                        key={e.id}
                                                    >
                                                        {e?.quantity} free{' '}
                                                        {e?.product?.name} on
                                                        order #{e.order}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                </div>
                            )}
                            {discount.length === 1 ? (
                                <div className="mt-2 text-xs">
                                    {discount?.[0]?.type === 'percentage'
                                        ? `${discount[0].value / 100}% off on ${
                                              // eslint-disable-next-line no-nested-ternary
                                              getOrderValue(
                                                  promotion.orders,
                                                  discount[0].order
                                              ) > 1
                                                  ? `your first ${
                                                        discount[0].order > 0
                                                            ? discount[0].order
                                                            : promotion.orders
                                                    } orders`
                                                  : getOrderValue(
                                                        promotion.orders,
                                                        discount[0].order
                                                    ) === 1
                                                  ? 'your first order'
                                                  : 'every order'
                                          }`
                                        : `$${discount[0].value / 100} off on ${
                                              // eslint-disable-next-line no-nested-ternary
                                              getOrderValue(
                                                  promotion.orders,
                                                  discount[0].order
                                              ) > 1
                                                  ? `your first ${
                                                        discount[0].order > 0
                                                            ? discount[0].order
                                                            : promotion.orders
                                                    } orders`
                                                  : getOrderValue(
                                                        promotion.orders,
                                                        discount[0].order
                                                    ) === 1
                                                  ? 'your first order'
                                                  : 'every order'
                                          }`}
                                </div>
                            ) : (
                                <div className="mt-2 text-xs">
                                    {discount.map((e: any) => (
                                        <div
                                            key={e.order}
                                            className="my-2 flex justify-between"
                                        >
                                            <div>
                                                {e.order
                                                    ? `Order ${e.order}`
                                                    : 'Other orders'}
                                            </div>
                                            <div>
                                                {e.type === 'percentage'
                                                    ? `${e.value / 100}% off`
                                                    : `$${e.value / 100} off`}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {promotion?.usable &&
                        items?.length! > 0 &&
                        !promotion?.chooseItems && (
                            <div>
                                <div className="mt-1 mb-3 text-xs">
                                    Your first order :
                                </div>
                                {items?.map((e: any) => (
                                    <div key={e.id}>
                                        <BillingProductListingCard
                                            name={e.product.name}
                                            productAmount={e.quantity}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                </div>
            )}
        </div>
    );
}

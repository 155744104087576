import * as Sentry from '@sentry/react';
import { AxiosError, AxiosResponse } from 'axios';
import { FormikHelpers } from 'formik';
import { AnySchema, InferType } from 'yup';
import LaravelResponse from '@/models/LaravelResponse';
import { useKlaviyoRequestError } from '@/services/klaviyo';
import {
    setAttemptedCheckout,
    setUnauthenticated,
    setUnexpectedError,
} from '@/store/getStarted/getStartedErrorSlice';
import { setLoading } from '@/store/getStarted/getStartedLoadingSlice';
import { setAttemptedGiftCardsCheckout } from '@/store/giftCards/giftCardsErrorSlice';
import { useUser } from '@/store/user/userSlice';
import handleFormErrors from '@/utils/handleFormErrors';
import messages from '@/utils/messages';

type SuccessArgs<S extends AnySchema, R extends AxiosResponse> = {
    response: R;
    values: InferType<S>;
    castValue: InferType<S>;
    helpers: FormikHelpers<InferType<S>>;
};

export default function useHandleSubmit<
    S extends AnySchema,
    R extends AxiosResponse
>(
    castSchema: S,
    axiosMutation: (castValue: InferType<S>) => Promise<R>,
    onSuccess?: (args: SuccessArgs<S, R>) => any | Promise<any>,
    dispatch?: any
) {
    const [user] = useUser();
    const klaviyoRequestError = useKlaviyoRequestError;

    return async (values: any, helpers: FormikHelpers<any>) => {
        const cast = castSchema.cast(values, {
            stripUnknown: true,
        });
        try {
            if (dispatch) {
                dispatch(setLoading(true));
            }
            const response = await axiosMutation(cast);
            if (response.status < 200 || response.status > 299) {
                helpers.setStatus({ error: messages.errors.internal });
                throw Error(
                    `Unsuccessful response status (${response.status}).`
                );
            }
            if (onSuccess)
                onSuccess({ castValue: cast, response, values, helpers });
        } catch (error) {
            const { response } = error as AxiosError<LaravelResponse>;
            if (response) {
                if (user) {
                    Sentry.setUser({ email: user.email });
                }
                Sentry.captureException(error);
                klaviyoRequestError(user, error);
                dispatch(setLoading(false));
                if (response.status !== 422) {
                    dispatch(setAttemptedCheckout(true));
                    dispatch(setAttemptedGiftCardsCheckout(true));
                }
                if (response.status === 422 && response?.data?.errors?.number) {
                    dispatch(setAttemptedCheckout(true));
                    dispatch(setAttemptedGiftCardsCheckout(true));
                }
                if (response.status === 401 || response.status === 419) {
                    dispatch(setUnauthenticated(true));
                }
                if (
                    response.status !== 401 &&
                    response.status !== 419 &&
                    response.status !== 422
                ) {
                    dispatch(setUnexpectedError(true));
                }
                // TODO: Handle the LaravelError being thrown from transformResponse
                if (response.data?.errors) {
                    const { errors } = response.data;
                    if (helpers.setErrors) {
                        helpers.setErrors(errors);
                    }
                    handleFormErrors(helpers, errors);
                } else {
                    if (helpers.setStatus) {
                        helpers.setStatus({ error: messages.errors.internal });
                    }
                    throw error;
                }
            }
        }
    };
}

import { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

export default function ScrollToTopLayout() {
    const { pathname } = useLocation();

    useEffect(() => {
        const { scrollingElement } = document;
        if (scrollingElement) {
            scrollingElement.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant' as any,
            });
        }
    }, [pathname]);

    return <Outlet />;
}
